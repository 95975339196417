import { useQuery, useQueryClient } from 'react-query';

import { api } from '~/api';
import { sortCartItemsByBundle } from '~/features/cart/cart';
import { subscriptionType } from '~/features/subscriptions/subscription';

/**
 * Custom hook for fetching a customer's cart - will only return a cart with an "open" status if a user is logged in.
 *
 * @param {Object} options - Additional options for the query.
 * @returns {Object} An object of the customer's open cart.
 * @property {Object} cart - The cart object containing details such as contents, pricing, etc.
 * @property {Array} bundledCartItems - An array of cart items sorted by bundle.
 * @property {number} lawnAddOnSubscriptionsPrice - The total price of lawn add-on subscription products.
 * @property {boolean} isLoading - A flag indicating whether the cart data is currently being loaded.
 * @property {Error} error - An error object if there is an issue fetching the cart data.
 * @property {...rest} rest - Additional properties returned by the useQuery hook.
 *
 */
export const useCart = (options) => {
  const {
    data: cart,
    isLoading,
    error,
    ...rest
  } = useQuery('cart', () => api.cart.find(), {
    ...options,
  });

  const lawnAddOnSubscriptionsPrice = getLawnAddOnsPrice(cart);

  const bundledCartItems = sortCartItemsByBundle(cart);

  return {
    cart,
    bundledCartItems,
    lawnAddOnSubscriptionsPrice,
    isLoading,
    error,
    ...rest,
  };
};

/**
 * Custom hook for fetching a customer's specific cart.
 * Looks like we won't be using this until add-to-shipment/order is revived.
 *
 * @param {string} cartUuid - The UUID of the cart to retrieve.
 * @param {Object} options - Additional options for the query.
 * @returns {Object} An object of a customer's specific cart.
 * @property {Object} cart - The cart object containing details such as contents, pricing, etc.
 * @property {Array} bundledCartItems - An array of cart items sorted by bundle.
 * @property {number} lawnAddOnSubscriptionsPrice - The total price of lawn add-on subscription products.
 * @property {boolean} isLoading - A flag indicating whether the cart data is currently being loaded.
 * @property {Error} error - An error object if there is an issue fetching the cart data.
 * @property {...rest} rest - Additional properties returned by the useQuery hook.
 *
 */
export const useCartByUuid = (cartUuid, options) => {
  const {
    data: cart,
    isLoading,
    error,
    ...rest
  } = useQuery(['cart', cartUuid], () => api.cart.findByUuid(cartUuid), {
    ...options,
    enabled: cartUuid?.length > 0,
  });

  const lawnAddOnSubscriptionsPrice = getLawnAddOnsPrice(cart);

  const bundledCartItems = sortCartItemsByBundle(cart);

  return {
    cart,
    bundledCartItems,
    lawnAddOnSubscriptionsPrice,
    isLoading,
    error,
    ...rest,
  };
};

/**
 * Custom hook for getting a cart with tax calculated and refetch
 * the purchase order and shipment history.
 *
 * @param {string} cartUuid - The UUID of the cart to retrieve.
 * @param {Object} options - Additional options for the query.
 * @returns {Object} An object of a customer's specific cart.
 * @property {Object} cart - The cart object containing details such as contents, pricing, etc.
 * @property {Array} bundledCartItems - An array of cart items sorted by bundle.
 * @property {number} lawnAddOnSubscriptionsPrice - The total price of lawn add-on subscription products.
 * @property {boolean} isLoading - A flag indicating whether the cart data is currently being loaded.
 * @property {Error} error - An error object if there is an issue fetching the cart data.
 * @property {...rest} rest - Additional properties returned by the useQuery hook.
 *
 */
export const useConfirmCartByUuid = ({ cartUuid, options }) => {
  const queryClient = useQueryClient();

  const {
    data: cart,
    isLoading,
    error,
    ...rest
  } = useQuery('confirmedCart', () => api.cart.confirmCart(cartUuid), {
    ...options,
    enabled: Boolean(cartUuid),
    staleTime: 0,
    cacheTime: 0,
  });

  if (cart) {
    queryClient.refetchQueries(['purchaseOrderAndShipmentHistory']);
  }

  const lawnAddOnSubscriptionsPrice = getLawnAddOnsPrice(cart);

  const bundledCartItems = sortCartItemsByBundle(cart);

  return {
    cart,
    bundledCartItems,
    lawnAddOnSubscriptionsPrice,
    isLoading,
    error,
    ...rest,
  };
};

/**
 * Deconstructs a cart, extracting lawnAddOnSubscriptionsPrice.
 *
 * @param {Object} cart - The cart object to be deconstructed.
 * @returns {Object} An object containing specific information extracted from the cart.
 * @property {number} lawnAddOnSubscriptionsPrice - The total price of lawn add-on subscription products.
 *
 * @example
 * const { lawnAddOnSubscriptionsPrice, bundledCartItems } = deconstructCart(myCart);
 */
const getLawnAddOnsPrice = (cart) => {
  const lawnAddOnSubscriptionProducts = cart?.contents?.filter(
    (product) =>
      Boolean(product?.bundleUuid) &&
      product.sku.subscriptionType !== subscriptionType.LAWN_PLAN
  );

  let lawnAddOnSubscriptionsPrice = 0;

  if (lawnAddOnSubscriptionProducts?.length > 0) {
    lawnAddOnSubscriptionsPrice = lawnAddOnSubscriptionProducts.reduce(
      (total, product) => total + product.sku.unitPrice * product.quantity,
      0
    );
  }

  return lawnAddOnSubscriptionsPrice;
};
