import { getValidationErrorMessageFor } from '~/api/api-errors';
import { subscriptionType } from '~/features/subscriptions/subscription';
import { cookies } from '~/utils/cookie';
import { captureException } from '~/utils/exception-tracking';

export const QUERY_PARAM_NAME = 'coupon';
export const COOKIE_NAME = 'QUERY_PARAM_COUPON';

/**
 * Store a coupon code pulled from the URL and call the provided
 * callback with the coupon code
 */
export const saveCouponFromQueryParams = (queryParams, onCookie) => {
  const coupon = queryParams.get(QUERY_PARAM_NAME);
  if (!coupon) {
    return false;
  }

  try {
    cookies.set(COOKIE_NAME, coupon, { expires: 30 });
    onCookie && onCookie(coupon);
    return true;
  } catch (e) {
    captureException(e);
    return false;
  }
};

/**
 * Return the coupon code that was pulled from the URL and saved to the device
 */
export const getSavedUrlCoupon = () => {
  try {
    return cookies.get(COOKIE_NAME);
  } catch (e) {
    captureException(e);
    return null;
  }
};

/**
 * Remove a stored coupon code that was pulled from the URL
 */
export const deleteSavedUrlCoupon = () => {
  try {
    cookies.remove(COOKIE_NAME);
    return true;
  } catch (e) {
    captureException(e);
    return false;
  }
};

/**
 * Return the default credit card or null
 */
export const getDefaultCard = (cart, defaultCard) => {
  if (!cart || !cart.customer || !cart.customer.cards) {
    return null;
  }

  return cart.customer.cards.find((c) => c.id === cart.customer.defaultCard);
};

/**
 * Check if a cart item is a Smart Lawn Plan
 */
export const isPlanItem = (cartItem) => cartItem.sku.isPlan;

export const isSubscriptionItem = (cartItem) =>
  [
    subscriptionType.LAWN_PLAN,
    subscriptionType.MOSQUITO,
    subscriptionType.TICK,
    subscriptionType.TOTAL_HOME,
  ].includes(cartItem.sku.subscriptionType);

/**
 * Return an appropriate error message based on an API response
 */
export const parseCouponError = (err) => {
  return (
    getValidationErrorMessageFor(err, 'couponCode') ||
    'There was a problem adding your coupon code'
  );
};

/**
 * Return a map of { [bundleUuid || 'addons']: CartItem[] }
 */
export const sortCartItemsByBundle = (cart = {}) => {
  const itemsByBundle = cart.contents?.reduce((acc, item) => {
    if (!item.bundleUuid) {
      const addonItems = acc['addons'] || [];

      return {
        ...acc,
        addons: [...addonItems, item],
      };
    }

    const items = acc[item.bundleUuid] || [];

    return {
      ...acc,
      [item.bundleUuid]: [...items, item],
    };
  }, {});

  return itemsByBundle || {};
};

/**
 * Given an array of sku id strings find the matching cart items
 */
export const skusToCartItems = (cart, skus) => {
  return skus
    ?.map((sku) =>
      cart?.contents?.find((cartItem) => cartItem.sku.skuId === sku)
    )
    .filter(Boolean);
};

/**
 * Return the correct cart/PO.
 * With the data model refactor, lawn plans are now distributed across multiple carts/POs.
 * The response from /api/carts/ gives us a cart with a master purchase order and
 * child purchase orders under the master po.
 * This helper function will determine which cart/PO (master or child) to use.
 *
 * -- if:
 *      - useBaseCart is true - this is purely for historical data until we get the PO designs implemented
 *      - the cart/PO has a purchasedAt date (for post-purchase use) OR
 *      - the cart/PO does not contain a lawn plan OR
 *      - the cart/PO does contain a flex pay lawn plan
 *          - return the top level cart
 *
 * -- else (this would mean a user has yet to pay for a lawn plan with 'pay in full' selected)
 *      - return the master cart
 */
export const cartPurchaseOrderSelector = (cart, useBaseCart = false) => {
  if (
    useBaseCart ||
    cart?.purchasedAt ||
    !cart?.containsLawnPlan ||
    cart?.isLegacyFlexPay
  ) {
    return cart;
  } else {
    return cart.masterPurchaseOrder;
  }
};

// Takes in `childCarts` and creates category-separated pseudo-child-carts
// for the BundleCartItem UI
export function separateCartsByCategory(childCarts) {
  /**
   * TODO:
   * Extra credit: don't hardcode category separations (i.e. allow for a Pest grouping)
   */

  const categorizedCarts = {
    lawn: [
      ...childCarts
        .map((cart) => {
          const newContents = cart.contents.filter(
            (item) => item.subscriptionType !== subscriptionType.FLOWER
          );
          return {
            ...cart,
            bundleShippingItems: cart.bundleShippingItems.filter(
              (item) => item.subscriptionType !== subscriptionType.FLOWER
            ),
            contents: newContents,
            bundlePrice: newContents.reduce(
              (total, item) => total + item.totalPrice,
              0
            ),
          };
        })
        .filter((cart) => cart.contents.length !== 0),
    ],
    garden: [
      ...childCarts
        .map((cart) => {
          const newContents = cart.contents.filter(
            (item) => item.subscriptionType === subscriptionType.FLOWER
          );
          return {
            ...cart,
            bundleShippingItems: cart.bundleShippingItems.filter(
              (item) => item.subscriptionType === subscriptionType.FLOWER
            ),
            contents: newContents,
            bundlePrice: newContents.reduce(
              (total, item) => total + item.totalPrice,
              0
            ),
          };
        })
        .filter((cart) => cart.contents.length !== 0),
    ],
  };

  return categorizedCarts;
}
