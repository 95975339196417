export const cartApi = (http) => {
  return {
    find: async () => {
      return http.get('/api/carts/');
    },

    findByUuid: async (cartUuid) => {
      return http.get(`/api/carts/${cartUuid}/`);
    },

    findNextUnpaid: async () => {
      return http.get('/api/carts?nextUnpaidOnly=true');
    },

    create: async () => {
      return http.post('/api/carts/', {});
    },
    // activating a cart will use a cart UUID and set that cart to the
    // user's active cart. Any items (sans bundles) in the user's current
    // cart will be carried over to the new cart
    activate: async (cartUuid) => {
      return http.post(`/api/carts/activate`, {
        cartUuid,
      });
    },

    // Send `isSubscription` so that we perform subscription-related validation on the BE
    add: async (cartUuid, sku, quantity, isSubscription, anonymousId) => {
      return http.put(`/api/carts/${cartUuid}/`, {
        skuId: sku,
        quantity,
        isSubscription,
        anonymousId,
      });
    },

    addMultiple: (cartUuid, items) => {
      return http.put(`/api/carts/${cartUuid}/`, items);
    },

    updateQuantity: async (cartUuid, cartItem, quantity) => {
      const previousQuantity = cartItem.quantity;
      const quantityChange = Number(quantity) - Number(previousQuantity);

      return http.put(`/api/carts/${cartUuid}/`, {
        skuId: cartItem.sku.skuId,
        quantity: quantityChange,
      });
    },

    updateProcessDate: async (cartUuid, processDate) => {
      return http.patch(`/api/carts/${cartUuid}/process-date/`, {
        processDate,
      });
    },

    remove: async (cartUuid, cartItem) => {
      return http.put(`/api/carts/${cartUuid}/`, {
        skuId: cartItem.sku.skuId,
        quantity: -1 * cartItem.quantity,
      });
    },

    removeMultiple: async (cartUuid, items) => {
      const itemsWithNegativeQty = items.map((item) => ({
        bundleUuid: item.bundleUuid,
        skuId: item.sku.skuId,
        quantity: -1 * item.quantity,
      }));
      return http.put(`/api/carts/${cartUuid}/`, itemsWithNegativeQty);
    },

    confirmOrder: async (cartUuid, paymentMethodId) => {
      return http.post('/api/carts/order-confirm/', {
        cartUuid,
        paymentMethodId,
      });
    },

    addCoupon: async (coupon) => {
      return http.post('/api/carts/coupon/', {
        couponCode: coupon,
      });
    },

    removeCoupon: async () => {
      return http.delete('/api/carts/coupon/');
    },

    // Used to calculated Sales Tax for the user's Cart
    // (AFTER we get their shipping address)
    confirmCart: async (cartUuid) => {
      return http.get(`/api/carts/confirm/${cartUuid}/`);
    },

    // Used to check for Out of Stock items in a Cart (used on the Cart page)
    validate: async () => {
      return http.get(`/api/carts/validate/`);
    },

    // Used as a pre-flight check before sending payment to Stripe.
    // checks that the payment intent and PO total still match up.
    // this eliminates anyone using multiple tabs and editing
    // their cart to get free stuff
    paymentValidation: async (cartUuid, providerChargeId) => {
      return http.post(`/api/carts/${cartUuid}/payment-validation/`, {
        provider_charge_id: providerChargeId,
      });
    },

    updatePlanPaymentType: async (cartUuid, isFlexPay) => {
      return http.patch(`/api/carts/${cartUuid}/`, {
        isLegacyFlexPay: isFlexPay,
      });
    },

    attachShippingAddress: async (cartUuid, shippingAddressUuid) => {
      return http.put('/api/carts/shipping_address/', {
        purchaseOrderUuid: cartUuid,
        shippingAddressUuid,
      });
    },

    fetchChildCarts: async (cartUuid) => {
      return await http.get(`/api/v2/carts/${cartUuid}/child_carts/`);
    },

    associateCartWithUser: async (cartUuid) => {
      return await http.put(`/api/carts/associate/`, {
        purchase_order_uuid: cartUuid,
      });
    },

    processShipNowOrder: async (cartUuid) => {
      return http.post(`/api/carts/ship-now/`, {
        cartUuid,
      });
    },

    skipShipment: async (cartUuid) => {
      return http.patch(`/api/carts/skip/`, {
        cartUuid,
      });
    },
  };
};
