import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder({
  dataset: 'production',
  projectId: 'afv8yn22',
});

const DEFAULT_TITLE = 'Sunday Lawn Care';
const DEFAULT_IMAGE = '/images/unboxing-right-1000w.png';

export const generateArticleOpenGraphTags = async ({ request, fetch }) => {
  const slug = request.params.slug;
  const params = new URLSearchParams({
    query: `*[_type == 'article' && slug.current == "${slug}"][0] {
      heroImage,
      seo,
      shortDescription,
      title,
    }`,
  });
  const res = await (
    await fetch(
      `https://afv8yn22.apicdn.sanity.io/v2021-05-25/data/query/production?${params}`
    )
  ).json();
  const article = res.result;

  return [
    {
      property: 'og:title',
      content: article?.seo?.documentTitle || article?.title || DEFAULT_TITLE,
    },
    {
      property: 'og:description',
      content: article?.seo?.documentDescription || article?.shortDescription,
    },
    { property: 'og:type', content: 'website' },
    {
      property: 'og:image',
      content: article?.heroImage
        ? builder
            .image(article.heroImage)
            .width(1200)
            .height(630)
            .fit('min')
            .url()
        : DEFAULT_IMAGE,
    },
    { property: 'og:url', content: 'https://www.getsunday.com/' + request.url },
  ];
};

export const generatePDPOpenGraphTags = async ({ request, fetch }) => {
  const slug = request.params.slug;
  const product = await (
    await fetch(`${process.env.SITE_URL}/bff/api/products/${slug}?og=true`)
  ).json();

  return [
    {
      property: 'og:title',
      content: product?.seo?.documentTitle || product?.name || DEFAULT_TITLE,
    },
    {
      property: 'og:description',
      content: product?.seo?.documentDescription || product?.tagLine,
    },
    { property: 'og:type', content: 'website' },
    {
      property: 'og:image',
      content: product?.primaryImage
        ? builder
            .image(product.primaryImage)
            .width(1200)
            .height(630)
            .fit('min')
            .url()
        : DEFAULT_IMAGE,
    },
    { property: 'og:url', content: 'https://www.getsunday.com/' + request.url },
  ];
};
