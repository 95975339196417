/**
 * Application routes are exposed as functions to allow dynamic values
 * to be passed in. Ex: `lawnShowRoute(123)` -> `/lawns/123`
 */

import queryString from 'query-string';

import { generatePDPImageLinks } from '~/sanity/image-preload';
import {
  generateArticleOpenGraphTags,
  generatePDPOpenGraphTags,
} from '~/sanity/open-graph-tags';

// Static
export const homeRoute = () => '/';
homeRoute.file = 'src/components/static/home/home-page.jsx';
homeRoute.links = async () => {
  return [
    {
      rel: 'preload',
      as: 'image',
      media: '(max-width: 767px)',
      href: '/images/home-page/dog-hero-mobile-REV-2.jpg',
    },
    {
      rel: 'preload',
      as: 'image',
      media: '(min-width: 768px)',
      href: '/images/home-page/dog-hero-desktop-REV-2.jpg',
    },
  ];
};
export const termsRoute = () => '/terms-and-conditions';
export const ingredientsRoute = () => '/our-ingredients';
export const customLawnPlanRoute = () => '/custom-lawn-plan';
customLawnPlanRoute.file =
  'src/components/static/smart-lawn-plan/smart-lawn-plan-page.jsx';
export const guaranteeRoute = () => '/sunday-guarantee';
export const ourMissionRoute = () => '/our-mission';
export const reviewsRoute = () => '/reviews/custom-lawn-plan';
export const aboutUsRoute = () => '/about';
export const privacySettingsRoute = () => '/privacy/settings';
export const retailLandingPageRoute = () => '/youryard';
export const careerPageRoute = () => '/careers';
export const helpCenterRoute = (queryParams) => {
  let path = '/help-center';
  if (queryParams) {
    path += '?' + queryString.stringify(queryParams);
  }
  return path;
};
export const cancellationPolicyRoute = () =>
  helpCenterRoute() + '?a=Cancellation-policy---id--Xz6AjrPLQ12fcVhQ570E2Q';
export const refundPolicyRoute = () =>
  helpCenterRoute() + '?a=Refund-policy---id--Q_IJuY70SBO0UnyDhuybeg';
export const skipShipmentPolicyRoute = () =>
  helpCenterRoute() +
  '?a=Skipping-a-Custom-Plan-shipment---id--uZ2MJq5FTaGumJYoVZ0QmQ';
export const contactUsRoute = () =>
  helpCenterRoute() + '?a=Contact-us---id--QtSDAj-sRAulw9HUjz4osg';

// Pro Supply
export const proSupplyRegistrationRoute = () => '/pro-supply';

// Alt HomePage
export const getStartedRoute = () => '/find';
getStartedRoute.file =
  'src/components/static/alt-home/get-started/get-started-page.jsx';
getStartedRoute.links = async () => {
  return [
    {
      rel: 'preload',
      as: 'image',
      media: '(max-width: 767px)',
      href: '/images/home-page/lawn-with-chairs-mobile.jpg',
    },
    {
      rel: 'preload',
      as: 'image',
      media: '(min-width: 768px)',
      href: '/images/home-page/lawn-with-chairs-desktop.jpg',
    },
  ];
};

export const neighborsPageRoute = (queryParams = {}) => {
  let path = '/neighbors';
  if (Object.keys(queryParams).length) {
    path += '?' + queryString.stringify(queryParams);
  }
  return path;
};

// Session management
export const signInRoute = () => '/login';
export const forgotPasswordRoute = () => '/forgot-password';
export const magicLinkRoute = (email = ':email', token = ':token') =>
  `/login/link/${email}/${token}`;

// Guest Checkout
export const guestCheckoutRoute = () => '/guest-checkout';

// Lawns
export const newLawnRoute = (queryParams = {}) => {
  let path = '/lawns/new';
  if (Object.keys(queryParams).length) {
    path += '?' + queryString.stringify(queryParams);
  }
  return path;
};
newLawnRoute.file = 'src/components/lawns/new/new-lawn-page.jsx';
export const lawnDetailsRoute = (lawnUuid = ':lawnUuid') =>
  `/lawns/${lawnUuid}`;
export const lawnQuestionnaireRoute = (lawnUuid = ':lawnUuid', page) => {
  let path = `/lawns/${lawnUuid}/questionnaire`;
  if (page) {
    path += `?page=${page}`;
  }
  return path;
};

export const planPreviewRoute = (lawnUuid = ':lawnUuid') =>
  `/lawns/${lawnUuid}/plan-preview`;

export const zipcodeLawnSizeRoute = (queryParams = {}) => {
  let path = '/lawn-size';
  if (Object.keys(queryParams).length) {
    path += '?' + queryString.stringify(queryParams);
  }
  return path;
};

// Garden
export const gardenContinuationPageRoute = (lawnUuid = ':lawnUuid') =>
  `/garden/${lawnUuid}/continuation`;
export const gardenQuestionnaireRoute = (lawnUuid = ':lawnUuid') =>
  `/garden/${lawnUuid}/questionnaire`;
export const gardenPlanSelectionRoute = (lawnUuid = ':lawnUuid') =>
  `/garden/${lawnUuid}/plan`;

// YardIssues and PestContinuation routes for GARDEN_FUNNEL_TEST
export const yardIssuesPageRoute = (queryParams = {}) => {
  let path = '/yard-issues';
  if (Object.keys(queryParams).length) {
    path += '?' + queryString.stringify(queryParams);
  }
  return path;
};

export const pestContinuationPageRoute = (lawnUuid = ':lawnUuid') => {
  return `/pest/${lawnUuid}/pest-continuation`;
};

export const neighborsFunnelPageRoute = (queryParams = {}) => {
  let path = '/your-neighbors';
  if (Object.keys(queryParams).length) {
    path += '?' + queryString.stringify(queryParams);
  }
  return path;
};

// Lawn plans
export const planDetailsRoute = (lawnUuid = ':lawnUuid') =>
  `/lawns/${lawnUuid}/lawn-plan`;
export const planNextBestRoute = (lawnUuid = ':lawnUuid') =>
  `/lawns/${lawnUuid}/lawn-plan/next-best`;
export const planAddOnsRoute = (lawnUuid = ':lawnUuid') =>
  `/lawns/${lawnUuid}/lawn-plan/add-ons`;

export const customPestPlanRoute = () => '/custom-pest-plan';
customPestPlanRoute.file =
  'src/components/static/smart-pest-plan/smart-pest-plan-page.jsx';

// Pest funnel
export const newPestRoute = (queryParams = {}) => {
  let path = '/pest/new';
  if (Object.keys(queryParams).length) {
    path += '?' + queryString.stringify(queryParams);
  }
  return path;
};
export const pestYardDetailsRoute = (lawnUuid = ':lawnUuid') =>
  `/pest/${lawnUuid}`;
export const pestQuestionnaireRoute = (lawnUuid = ':lawnUuid') =>
  `/pest/${lawnUuid}/questionnaire`;
export const pestSignUpRoute = (lawnUuid = ':lawnUuid') =>
  `/pest/${lawnUuid}/signup`;
export const pestPlanDetailsRoute = (lawnUuid = ':lawnUuid') =>
  `/pest/${lawnUuid}/plan`;
export const pestPlanNextBestRoute = (lawnUuid = ':lawnUuid') =>
  `/pest/${lawnUuid}/plan/next-best`;
export const pestPlanAddOnsRoute = (lawnUuid = ':lawnUuid') =>
  `/pest/${lawnUuid}/plan/add-ons`;

// Cart
export const cartRoute = () => '/cart';

// Checkout
export const checkoutShippingRoute = () => '/checkout/shipping';
export const checkoutPaymentRoute = () => '/checkout/payment';
export const checkoutConfirmationRoute = (cartUuid = ':cartUuid') =>
  `/checkout/${cartUuid}/confirmed`;

// Account
export const accountSettingsRoute = () => '/account/settings';
export const myPlanRoute = () => '/my-plan';
myPlanRoute.file = 'src/components/account/my-plan/my-plan-page.jsx';
export const lawnDataRoute = () => '/my-lawn-data';
export const subscriptionsRoute = () => '/account/subscriptions';
export const editSubscriptionRoute = (subscriptionUuid = ':subscriptionUuid') =>
  `/account/subscriptions/${subscriptionUuid}/edit`;
export const nextBoxRoute = () => '/account/next-box';

// Cancellation
export const cancellationBenefitsRoute = () => '/cancellation/benefits';
export const cancellationReasonsRoute = () => '/cancellation/reasons';
export const cancellationOffersRoute = () => `/cancellation/offers`;
export const cancellationConfirmationRoute = () => `/cancellation/confirmed`;

// Amazon
export const soilTestRegistration = () => '/soil-test-registration';

// Shed
export const shedRoute = () => '/shed';
shedRoute.file = 'src/components/the-shed/shed-home.jsx';

// We skip adding a default value for subCategory
// on purpose to simplify using this route function.
// Rather than checking if an article has a subCategory
// we just always try to use it, and if it doesn't exist
// we want to only use the /category/slug route
export const plantingGuidesRoute = () => `/grow`;
export const shedCategoryRoute = ({
  category = ':category',
  subCategory,
} = {}) => {
  if (subCategory) {
    return `/shed/categories/${category}/${subCategory}`;
  } else {
    return `/shed/categories/${category}`;
  }
};
shedCategoryRoute.file =
  'src/components/the-shed/category-page/category-page.jsx';

// We skip adding a default value for subCategory
// on purpose to simplify using this route function.
// Rather than checking if an article has a subCategory
// we just always try to use it, and if it doesn't exist
// we want to only use the /category/slug route
export const shedArticleRoute = ({
  category = ':category',
  slug = ':slug',
} = {}) => {
  return `/shed/${category}/${slug}`;
};
shedArticleRoute.file = 'src/components/the-shed/article/article-page.jsx';
shedArticleRoute.meta = generateArticleOpenGraphTags;

export const shedAllRoute = (queryParams = {}) => {
  let path = `/shed/all`;
  if (Object.keys(queryParams).length) {
    path += '?' + queryString.stringify(queryParams);
  }
  return path;
};

export const referralLandingPageRoute = () => `/referrals`;

// Radial Admin Route
export const radialAdminRoute = () =>
  '/radial-tools/11a2da6c-1bda-4295-83d0-163587452c50';

// Sunday Store

export const shopAllRoute = (queryParams = {}) => {
  let path = `/shop/all`;
  if (Object.keys(queryParams).length) {
    path += '?' + queryString.stringify(queryParams);
  }
  return path;
};
shopAllRoute.file = 'src/components/sunday-store/shop-all-page.jsx';

export const categoryPageRoute = (
  category = ':category',
  subCategory,
  queryParams = {}
) => {
  let path = `/shop/category/${category}`;
  if (subCategory) {
    path = `/shop/category/${category}/${subCategory}`;
  }

  if (Object.keys(queryParams).length) {
    path += '?' + queryString.stringify(queryParams);
  }

  return path;
};
categoryPageRoute.file = 'src/components/sunday-store/shop-all-page.jsx';

// Search
export const searchRoute = () => '/search';

export const lawnCareRoute = () => categoryPageRoute('lawn-care');
export const weedControlRoute = () =>
  categoryPageRoute('lawn-care', 'weed-control');
export const grassSeedRoute = () =>
  categoryPageRoute('lawn-care', 'grass-seed');
export const pestsRoute = () => categoryPageRoute('pest-control');
export const petsRoute = () => categoryPageRoute('lawn-care', 'pet');
export const gardenRoute = () => categoryPageRoute('garden');

export const sundayStoreProductDetailsRoute = ({
  category = ':category',
  slug = ':slug',
} = {}) => {
  return `/shop/${category}/${slug}`;
};
sundayStoreProductDetailsRoute.file =
  'src/components/sunday-store/components/product-details/product-details-page.jsx';
sundayStoreProductDetailsRoute.meta = generatePDPOpenGraphTags;
sundayStoreProductDetailsRoute.links = generatePDPImageLinks;

// Dynamic forms
export const dynamicFormPreviewRoute = (slug = ':slug') =>
  `/dynamic-forms/preview/${slug}`;

// Seed finder
export const seedFinderRoute = () => '/seed-finder';
export const seedFinderResultsRoute = () => '/seed-finder/results';

// Promo banners
export const promoBannerPreviewRoute = (slug = ':slug') =>
  `/promo-banner/preview/${slug}`;

export const brownSpotIdRoute = () => '/tools/brown-spot-id';
brownSpotIdRoute.file =
  'src/components/tools/brown-spot/brown-spot-landing-page.jsx';
export const brownSpotResultsRoute = () => '/tools/brown-spot-id/results';

// Regional SEO pages
export const regionalSeoIndexRoute = () => '/local-guide';
export const regionalSeoPageRoute = (slug = ':slug') => `/local-guide/${slug}`;
